.footer {
  font-size: 13px;
  color: $secondary;
  background-color: $light;
  border-top: 1px solid $gray-200;

  a {
    color: $secondary;
  }

  ul {
    li {
      margin-bottom: 4px;
    }
  }
}

.footer-top {
  padding: 3rem 0;
}

.footer-bottom {
  padding: .75rem 0;
  font-size: $font-size-sm;
  border-top: 1px solid $gray-200;
}

.footer-title {
  position: relative;
  font-size: $h5-font-size;
  color: $dark;
  padding: .75rem 0;
}

.footer-collapse-btn {
  display: flex;
  align-items: center;
  color: $gray-700 !important;
  font-size: $h5-font-size;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;

  &:hover,
  &:active {
    text-decoration: none;
  }

  i {
    margin-left: auto;
  }
}

@media (max-width: 768px) {
  .footer-collapse-btn {
    visibility: visible;
  }
}

@media (width: 768px) {
  .footer-collapse-btn {
    visibility: hidden;
  }
}

@media (min-width: 768px) {
  .footer .collapse:not(.show) {
    display: flex;
  }
}