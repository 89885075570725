.article-list2 {
  .article-item {
    position: relative;

    &:hover .card:after {
      height: 5.5rem;
    }

    &:hover .no-gutters .arrow-icon {
      background: url(../images/all_arrow_icon.png) no-repeat -40px -176px;
    }

    &:hover .no-gutters .card-day {
      padding-left: 1rem;
    }

    &:hover .no-gutters .card-year {
      transition: all 0.6s ease 0s;
      padding-left: 1rem;
    }

    &:hover .no-gutters .card-body {
      padding-left: 2rem !important;
      transition: all 0.6s ease 0s;
    }

    &.no-gutters div:nth-child(2) {
      padding-left: 0;
      transition: all 0.6s ease 0s;
    }

    .card {
      margin-bottom: 20px;
      border: unset;
      padding: 0.75rem 0;
      margin-bottom: 3rem !important;

      a {
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
      }

      a:active,
      a:hover {
        outline-width: 0;
      }

      a,
      ins {
        text-decoration: none;
        color: #262626;
      }

      :focus {
        outline: 0;
      }

      &:after {
        content: '';
        width: 2px;
        height: 0px;
        background: $gray-700;
        position: absolute;
        left: -1.5rem;
        top: 0px;
        transition: all 0.6s ease 0s;
      }

      .card-day {
        color: $text-muted-dark;
        padding: 0;
        transition: all 0.6s ease 0s;
        font-size: 32px;
        width: 6.5rem;
        display: block;
      }

      .card-year {
        display: inline-block;
        font-size: 12px;
        position: absolute;
        top: 38px;
        left: 0px;
        color: #a0a0a0;
        padding: 0;
        transition: all 0.6s ease 0s;
      }

      .card-body {
        padding: 0;
      }

      .arrow-icon {
        content: "";
        display: block;
        width: 40px;
        height: 40px;
        background: url(../images/all_arrow_icon.png) no-repeat -40px -146px;
        position: absolute;
        left: 1vw;
        top: 1vw;
        transition: all 0.6s ease 0s;
      }

      .no-gutters {
        transition: all 0.6s ease 0s;

        .card-title {
          color: $dark;
          margin-bottom: 1.25rem;
        }

        .card-text {
          color: $text-muted;
        }

        &:nth-child(3) {
          position: relative;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .article-list2 .card-body {
    padding-left: 1rem !important;
    transition: all 0.6s ease 0s;
  }
}

// 新闻详情页
.article-detail hr {
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
}

.article-detail.page-content {
  text-indent: 0rem;

  img {
    max-width: 792px;
    width: 100%;
  }

  blockquote hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}