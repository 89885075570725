$product-border-color: rgba($black, .06);

.product-list {
    margin-right: -.25rem;
    margin-left: -.25rem;

    [class^="col-"] {
        padding-left: .25rem;
        padding-right: .25rem;
    }

    .card {
        border: 0;
        border-radius: 0;
        transition: $transition;
        box-shadow: 0 0 0 rgba(0, 0, 0, .1);
        margin-bottom: 2.5rem;

        a {
            text-decoration: none;
            color: inherit;
        }

        .card-img-top {
            position: relative;
            border-radius: 0;
            height: $product-height;
            overflow: hidden;
            transition: $transition;

            img {
                width: 100%;
                transition: $transition;
            }

            .card-img-wrap {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 2px solid transparent;
                border-bottom: 0;
                transition: $transition;
            }
        }

        .card-title {
            font-size: 17px;
            margin-bottom: 1rem;
        }

        .card-text {
            color: $secondary;
            font-size: 13px;
            line-height: 25px;
            height: 50px;
            overflow: hidden;
        }

        .card-body {
            padding: 1.5rem;
        }

        .card-footer {
            position: relative;
            padding: .75rem 1.5rem;
            font-size: $font-size-sm;
            color: $gray-700;
            background-color: transparent;
            border-color: $product-border-color;
        }

        .card-more {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 42px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            border-left: 1px solid transparent;
            font-size: 17px;
            color: #fff;
        }

        &:hover {
            color: $white;
            background-color: $primary;
            box-shadow: 0 15px 30px rgba(0, 0, 0, .1);

            .card-img-top {
                img {
                    transform: scale(1.1);
                }

                .card-img-wrap {
                    border-color: $primary;
                }
            }

            .card-title,
            .card-text,
            .card-footer {
                color: $white;
                border-color: rgba(255, 255, 255, .1);
            }

            .card-more {
                border-color: rgba(255, 255, 255, .1);
            }
        }
    }
}

.cate-list {
    margin-bottom: 2rem;

    .nav {
        justify-content: center;

        .nav-item {
            position: relative;
            margin: 0 2.5px;

            &::after {
                content: '';
                position: absolute;
                top: 13px;
                right: -3px;
                width: 1px;
                height: 12px;
                background: rgba(0, 0, 0, .15);
                transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
            }

            &:nth-last-child(1)::after {
                width: 0;
            }

            .nav-link {
                border-radius: 0;
                padding: .5rem 1.5rem;
                color: $gray-600;
                transition: all .36s ease;

                &:hover,
                &:focus {
                    color: #fff;
                    background-color: $primary;
                }

                &.active {
                    color: #fff;
                }

                &.dropdown-toggle::after {
                    margin-left: 8px;
                }
            }
        }

        .show>.nav-link {
            color: #fff;
            background-color: $primary;
        }
    }

    @media (max-width: 576px) {
        .dropdown-menu {
            position: static;
            float: none;
        }
    }
}

.product-click-count {
    font-size: 12px;
    float: right;
    margin-top: 8px;
}

.product-gallery {
    .swiper-wrapper {
        .swiper-slide {
            height: 360px;
            overflow: hidden;
        }
    }
}
