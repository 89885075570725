html, body {
  height: 100%;
}

.page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100%;
}

.page-fill {
  flex: 1 1 auto;
}

.page-wrap {
	// padding-top: 146px;

	@media (max-width: 768px) {
		// padding-top: 112px;
	}
}
.page-banner {
	height: 300px;
	background-repeat: no-repeat;
	background-position: center;

	@media (max-width: 768px) {
		display: none;
	}
}

.page-title {
	text-align: center;
	margin-bottom: 1rem;
}

.page-content {
	margin-bottom: 3rem;
	font-size: 15px;
	text-indent: 2em;

	img {
		display: inherit;
		max-width: 100%;
		height: auto;
	}
}

.page-pagination {
	.pagination {
		justify-content: center;

		.page-item {
			margin: 0 4px;

			&.active .page-link {
				color: #ffffff;
				border-color: $primary;
				background-color: $primary;
			}
			&.disabled .page-link {
				color: $secondary;
			}
			.page-link {
				min-width: 38px;
				height: 38px;
				text-align: center;
				border-color: $gray-200;
				padding-top: 0;
				padding-bottom: 0;
				padding: 10px 12px;
				transition: all .12s ease;
				border-radius: $border-radius;
				color: $primary;

				&:hover {
					color: #ffffff;
					background-color: $primary;
					border-color: $primary;
				}
				&:hover, &:focus {
					box-shadow: 0 0 0 0;
					outline: none;
				}
			}
			&:first-child .page-link, &:last-child .page-link {
				border-radius: $border-radius;
				.page-link {
					font-size: 17px;
					padding: 8px 12px;
				}
			}
		}
	}
}

.main {
  padding: 0;
}

.breadcrumb {
	padding: 1rem 0;
	background-color: $white;
	margin-bottom: 0;
}

.breadcrumb-title {
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: $secondary;
}

.breadcrumb-item {
	color: $secondary;

	a {
		color: $secondary;
	}
	&.active {
		color: $primary;
	}
}

.custom-card {
	border: 0;
	box-shadow: $box-shadow;
	margin-bottom: 3rem;

	.card-body {
		padding: 3rem;
	}
	@media (max-width: 768px) {
		.card-body {
			padding: 1rem;
		}
	}
}

.contact-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding-left: 1rem;

	@media (max-width: 768px) {
		padding-left: 0;
	}
}

.contact-baidu-map {
	width: 100%;
	min-height: 500px;

	@media (max-width: 768px) {
		margin-bottom: 1rem;
	}
}

.scroll-to-top {
	display: none;
	position: fixed;
	right: $spacer;
	bottom: 4 * $spacer;
	color: #ffffff;
	background-color: $primary;
	line-height: normal;
	text-decoration: none;
	width: 42px;
	z-index: 1500;
	transition: all 0.36s ease;
	height: 42px;
	font-size: 22px;
	text-align: center;

	i {
		display: inline-block;
		margin-top: 8px;
	}
	&:hover {
		color: #ffffff;
		background-color: darken($primary, 5%);
		text-decoration: none;
	}
}



/* layout,布局css样式 */
/* css reset , 使DOM元素在不同浏览器中的默认样式去除或者统一起来,以防止不同浏览器的解析不同而造成格式错乱 */
* {
	margin: 0px;
	padding: 0px;
}
body {
	// background: #f6f6f6;
	font: 12px/1.5 tahoma, arial, Microsoft YaHei, sans-serif; /*字体默认12px,行间距1.5倍,以及一些常用字体*/
}
li {
	list-style: none;
}
img {
	display: block;
	border: none;
}
.fimg {
  overflow: hidden;
}
.fimg > img {
	width: 100%;
}
.fimg_d > img {
	height: 100%;
}
label {
	cursor: pointer;
}
input[type='checkbox'] {
	cursor: pointer;
}

/* *********************************定宽布局********************************* */
@media (min-width: 1200px) {
  .w {
    width: 100%;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    // background: #aaa;
  }
  .sw {
    width: 1200px;
    margin: 0 auto;
    // background: #ccc;
  }
}
@media (max-width: 1200px) {
  w {
    width: 100%;
    min-width: auto;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    // background: #aaa;
  }
  .w .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .sw {
    width: 100%;
    // background: #ccc;
  }
}
body {
  overflow-x: hidden;
}

/* *********************************通用flex布局********************************* */
/* flex容器样式，子元素横向排列，上下左右居中，子元素space-between */
.flex_row_sb {
	display: flex;
  justify-content: space-between;
  align-items: center;
}
/* flex容器样式，子元素横向排列，上下左右居中，子元素space-around */
.flex_row_sa {
	display: flex;
  justify-content: space-around;
  align-items: center;
}
/* flex容器样式，子元素纵向排列，上下左右居中，子元素space-between */
.flex_column_sb {
	display: flex;
	flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
/* flex容器样式，子元素纵向排列，上下左右居中，子元素space-around */
.flex_column_sa {
	display: flex;
	flex-direction: column;
  justify-content: space-around;
  align-items: center;
}


/* *********************************通用行数限制********************************* */
.text_of_1 {
	text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.text_of_2 {
	overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp:2;
  word-break:break-all;
  display:-webkit-box;
  -webkit-box-orient:vertical;
}
.text_of_3 {
	overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp:3;
  word-break:break-all;
  display:-webkit-box;
  -webkit-box-orient:vertical;
}
.text_of_4 {
	overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp:4;
  word-break:break-all;
  display:-webkit-box;
  -webkit-box-orient:vertical;
}
.text_of_5 {
	overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp:5;
  word-break:break-all;
  display:-webkit-box;
  -webkit-box-orient:vertical;
}



/* *********************************全局通用样式********************************* */
/* 隐藏类 */
.hide {
	display: none;
}
/* 完全平铺 */
.abs_full {
	position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
/* 超链样式 */
.link {
	color: #999;
	cursor: pointer;
	text-decoration: none;
}
.link:hover {
	color: #e80012;
}
.link-text {
	color: #999;
	text-decoration: none; 
}
/* 扁平化按钮 */
.btn {
	display: inline-block;
	padding: 0 20px;
	height: 40px;
	line-height: 40px;
	vertical-align: middle; /* 垂直居中 */
	border: none;
	background: #c60023;
	font-size: 17px;
	font-weight: bold;
	color: #fff;
	outline: none;
	cursor: pointer;
	text-decoration: none;
}
/* 小按钮 */
.btn-mini { /* 用继承btn的方式使用该样式 */
	height: 25px;
	line-height: 25px;
	font-size: 12px;
	padding: 0 10px;
}

