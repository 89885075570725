// Colors
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$blue: #467fcf;
$azure: #45aaf2;
$indigo: #6574cd;
$purple: #a55eea;
$pink: #f66d9b;
$red: #cd201f;
$orange: #fd9644;
$yellow: #f1c40f;
$lime: #7bd235;
$green: #5eba00;
$teal: #2bcbba;
$cyan: #17a2b8;
$theme: #20a003;

$text-muted: #9aa0ac;
$text-muted-dark: #6e7687;

$white: #fff;

$primary: $theme;
$secondary: $gray-600;
$success: $green;
$info: $azure;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$colors: (
  "blue": $blue,
	"azure": $azure,
	"indigo": $indigo,
	"purple": $purple,
	"pink": $pink,
	"red": $red,
	"orange": $orange,
	"yellow": $yellow,
	"lime": $lime,
	"green": $green,
	"teal": $teal,
	"cyan": $cyan,
	"white": $white,
	"gray": $gray-600,
	"gray-dark": $gray-800);

$theme-colors: ("primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"light": $light,
	"dark": $dark);

$font-size-base: .875rem;
$font-size-lg: 1rem;
$font-size-sm: .75rem;

$container-max-widths: (sm: 540px,
	md: 720px,
	lg: 1200px,
	xl: 1230px) !default;

$breadcrumb-divider: quote(">");

$box-shadow-header: 0 .25rem .5rem rgba(#000000, .08);

$dropdown-link-active-bg: $primary;
$dropdown-border-color: $gray-200;

$product-height: 220px;

$transition: all .36s ease;
