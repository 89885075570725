$header-border-color: $gray-200;

.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $white;
  box-shadow: $box-shadow-header;
  transition: all 0.36s ease-out;
  z-index: 2000;

  // @media (min-width: 1200px) {
  //     .container {
  //         max-width: 1340px;
  //     }
  // }

  .navbar-nav .nav-item .nav-link {
    font-size: $font-size-base;
  }
  
  &.expand {
    .navbar-top {
      height: 96px;
    }

    .navbar-bottom {
      height: 50px;
      // background: #20a003;
      .navbar-nav {
        height: 50px;
      }
      // .navbar-nav .nav-item {
      //   &:hover {
      //     background: #fff;
      //   }
      //   &:hover .nav-link {
      //     color: #20a003;
      //   }
      // }
      .navbar-nav .nav-item .nav-link {
        height: 50px;
        line-height: 48px;
        color: #444;
      }
    }
  }
}

.navbar-top {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid $header-border-color;
  overflow: hidden;
}

.navbar-top,
.navbar-bottom {
  transition: all .2s ease;
}

.navbar-contact {
  display: block;
  text-align: right;
  font-family: 'Arial';

  a {
    display: block;
    margin-bottom: .5rem;
    color: $gray-500;
    font-size: 13px;
    line-height: 13px;
  }

  span {
    font-size: 20px;
    line-height: 20px;
    color: $dark;

    i {
      font-size: 18px;
      color: $primary;
      margin-right: 4px;
    }
  }
}

.navbar-bottom {
  padding-top: 0;
  padding-bottom: 0;
  height: 64px;

  .navbar-nav {
    height: 64px;
    margin: 0;
    padding: 0;
    transition: all .2s ease;

    .nav-item {
      .nav-link {
        height: 64px;
        line-height: 62px;
        margin-top: -1px;
        padding: 0 1.75rem;
        border-top: 2px solid transparent;
        transition: all .2s ease;

        &:hover {
          color: $primary;
        }
      }

      &.active {
        .nav-link {
          border-color: $primary;
          color: $primary;
        }
      }
    }
  }


  .navbar-toggler-wrap {
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 992px) {
    .navbar-toggler-wrap {
      height: 50px;
    }

    .navbar-nav .nav-item .nav-link {
      padding: 0;
    }

    .navbar-nav .nav-item {
      border: 0;
    }
  }
}

.header-dropdown-menu {
  margin-top: -1px;
  border-radius: 0;
  border-color: $header-border-color;
  border-top: 2px solid #fff;

  .dropdown-submenu .dropdown-menu {
    top: -10px;
    border-radius: 0;
    border-color: $header-border-color;
  }
}

@media (max-width: 992px) {
  .header .navbar-bottom {
    height: auto;

    .navbar-nav {
      height: auto;

      .nav-item .nav-link {
        border-color: transparent;
        height: 50px;
        line-height: 48px;
      }
    }
  }

  .header.expand .navbar-bottom {
    height: auto;

    .navbar-nav {
      height: auto;

      .nav-link {
        border-color: transparent;
      }
    }
  }
}

@media (max-width: 768px) {
  .header.expand .navbar-top {
    height: auto;
    padding: .5rem 1rem;

    .navbar-brand img {
      height: 32px;
    }
  }

  .navbar-contact {

    a {
      margin-bottom: .5rem;
      font-size: 12px;
      line-height: 12px;
    }

    span {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
}

.navbar-light {
  .navbar-toggler {
    border-color: #FFF;

    span {
      color: #fff;
    }
  }
}


.common_banner {
  width: 100%;
  height: 40vw;
  position: relative;
}
.common_banner > img {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 10;
}
// common_banner
@media (min-width: 768px) {
  .header_back {
    width: 100%;
    height: 146px;
  }
}
@media (max-width: 768px) {
  .header_back {
    width: 100%;
    height: 112px;
  }
}

