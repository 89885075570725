// 公共头部
.common_header {
  width: 100%;
  height: auto;
  text-align: center;
  color: #2ea113;
  @media (min-width: 1200px) {
    margin-bottom: 30px;
    span {
      font-size: 33.7px;
    }
    P {
      font-size: 30px;
      height: 40px;
      line-height: 40px;
      width: 200px;
    }
  }
  @media (max-width: 1200px) {
    margin-bottom: 15px;
    span {
      font-size: 20px;
    }
    P {
      font-size: 18px;
      height: 40px;
      line-height: 40px;
      width: 130px;
    }
  }
  span {
    font-family: SimSun-ExtB;
  }
  p {
    margin: 0 auto;
    background: url(../images/n_indexCommonHeader_back.png);
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.product_center {
  height: auto;
  // 切换按钮部分
  .switch_btn_wrp {
    width: 100%;
    height: auto;
    @media (min-width: 1200px) {
      padding: 0px 115px;
      margin-bottom: 40px;
      font-size: 18px;
      > li {
        width: 160px;
        height: 45px;
        line-height: 43px;
        margin: 0px 17px;
        margin-bottom: 15px;
      }
    }
    @media (max-width: 1200px) {
      padding: 0vw 0vw;
      margin-bottom: 2vw;
      font-size: 2.3vw;
      > li {
        width: 15.5vw;
        height: 5.5;
        line-height: 5.3vw;
        margin: 0px 2vw;
        margin-bottom: 2vw;
      }
    }
    box-sizing: border-box;
    color: #2ea113;
    flex-wrap: wrap;
    > li {
      border: 1px solid #2ea113;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      &.activated {
        background: #2ea113;
        color: #fff;
      }
    }
  }
  // 切换内容部分
  .switch_cont_wrp {
    width: 100%;
    min-height: 530px;
    background: #fff;
    position: relative;
    .switch_cont_item {
      width: 100%;
      height: 0px;
      padding: 0px 20px;
      box-sizing: border-box;
      overflow: hidden;
      background: skyblue;
      &.activated {
        height: auto;
        background: #fff;
        overflow: hidden;
      }
      > div {
        @media (min-width: 1200px) {
          width: 280px;
          height: 220px;
          margin-bottom: 30px;
        }
        @media (max-width: 1200px) {
          width: 280px;
          height: 245px;
          margin-bottom: 3vw;
        }
        background: #fff;
        flex-grow: 1;
        > a {
          width: 100%;
          height: 100%;
          margin: 0 auto;
          > img {
            transition: all 0.5s ease;
            transform: scale(1)
          }
          &:hover {
            > img {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .row {
      margin-right: 0px;
      margin-left: 0px;
    }
  }
  .sw {
    height: 100%;
    article {
      width: 100%;
    }
  }
}

// 关于我们
.about_us {
  background: url(../images/n_about_background.png);
  background-size: auto 100%;
  background-position: center;
  @media (min-width: 1200px) {
    height: 640px;
    margin-bottom: 60px;
    .about_item {
      height: 440px;
      margin: 100px 0px;
    }
  }
  @media (max-width: 1200px) {
    height: auto;
    margin-top: 10vw;
    margin-bottom: 8vw;
    .about_item {
      height: 70vw;
      margin-top: 20px;
    }
  }
  .about_image {
    .cube_border {
      width: 80%;
      height: 80%;
      border: 1px solid #fff;
      position: absolute;
      left: 5%;
      top: 10%;
    }
    > img {
      width: 80%;
      position: absolute;
      top: 18%;
      left: 13%;
    }
  }
  .sw {
    height: 100%;
    box-sizing: border-box;
    .about_item {
      position: relative;
    }
    .about_ont {
      @media (min-width: 1200px) {
        padding: 55px 0px;
        box-sizing: border-box;
        > b {
          display: block;
          width: auto;
          height: 65px;
          line-height: 65px;
          border-bottom: 1px solid #fff;
          font-size: 26px;
          color: #fff;
          float: left;
        }
        > article {
          width: 100%;
          height: auto;
          float: left;
          font-size: 16px;
          color: #fff;
          text-indent: 33px;
          line-height: 30px;
          a {
            width: 80px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #fff;
            color: #2ea113;
            font-size: 12px;
            float: right;
            text-indent: 0px;
          }
        }
      }
      @media (max-width: 1200px) {
        padding: 0vw 3vw;
        box-sizing: border-box;
        > b {
          display: block;
          width: auto;
          height: 13vw;
          line-height: 13vw;
          border-bottom: 1px solid #fff;
          font-size: 5vw;
          color: #fff;
          float: left;
          margin-bottom: 2vw;
        }
        > article {
          width: 100%;
          height: auto;
          float: left;
          font-size: 3.5vw;
          color: #fff;
          text-indent: 33px;
          line-height: 5vw;
          a {
            width: 15vw;
            height: 6vw;
            line-height: 6vw;
            text-align: center;
            background: #fff;
            color: #2ea113;
            font-size: 2.5vw;
            float: right;
            text-indent: 0px;
            margin-top: -4vw;
          }
        }
      }
    }
  }
}

// 案例展示
.example_wrp {
  height: auto;
  overflow: hidden;
  margin-bottom: 20px;
  .product_item {
    width: auto;
    height: 321px;
    margin-bottom: 50px;
    flex-shrink: 1;
    .example_cover_wrp {
      width: 268px;
      height: 100%;
      border-radius: 20px;
      margin: 0 auto;
      display: block;
    }
  }
}

// 新闻资讯
.news_wrp {
  height: auto;
  margin-bottom: 40px;
  .newsList_wrp {
    .news_item {
      width: 100%;
      height: 85px;
      float: left;
      text-decoration: none;
      @media (min-width: 1200px) {
        margin-bottom: 20px;
      }
      @media (max-width: 1200px) {
        margin-bottom: 0px;
      }
      padding: 0px 15px;
      box-sizing: border-box;
      &:hover {
        .news_cont {
          > span {
            text-decoration: underline;
          }
        }
      }
      > span {
        width: 12%;
        height: 70%;
        font-family: Micorsoft YaHei UI;
        font-size: 46px;
        color: #20a003;
        transform: scale(0.8, 1);
      }
      .news_cont {
        width: 70%;
        height: 55%;
        align-items: flex-start;
        > span {
          display: block;
          width: 100%;
          font-size: 15px;
          color: #000;
        }
        > p {
          margin-bottom: 0px;
          width: 100%;
          font-size: 12px;
          color: #444;
        }
      }
      .date_item_wrp {
        width: 12%;
        height: 70%;
        margin-right: 3%;
        position: relative;
        > span {
          position: absolute;
          @media (min-width: 1200px) {
            &:nth-child(1) {
              font-size: 25px;
              left: 7px;
              top: 6px;
              color: #000;
            }
            &:nth-child(2) {
              font-size: 14px;
              right: 15px;
              bottom: 10px;
              color: #444;
            }
          }
          @media (max-width: 1200px) {
            &:nth-child(1) {
              font-size: 25px;
              left: 0vw;
              top: 0vw;
              color: #000;
            }
            &:nth-child(2) {
              font-size: 14px;
              right: 0vw;
              bottom: 3vw;
              color: #444;
            }
          }
         
        }
      }
    }
  }
  .newsList_sideImage_wrp {
    padding: 0px 60px;
    box-sizing: border-box;
  }
}


