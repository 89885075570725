.swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    opacity: 1;
    border: 2px solid transparent;
    background-color: rgba($black, .1);
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
}

.swiper-pagination-bullet-active {
    background-color: transparent;
    border-color: $primary;
    width: 12px;
    height: 12px;
}