.article-list .card {
  margin-bottom: 2rem;
  overflow: hidden;
  border: unset;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: unset;

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  a:active, a:hover {
    outline-width: 0;
  }

  a,
  ins {
    text-decoration: none;
    color: #262626;
  }

  :focus {
    outline: 0;
  }

  img {
    border-radius: unset;
    transition: all 0.36s ease;
    -webkit-transition: all 0.36s ease;
    -moz-transition: all 0.36s ease;
    -o-transition: all 0.36s ease;
    -ms-transition: all 0.36s ease;
  }

  &:hover img {
    transform: scale(1.08);
    -webkit-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -o-transform: scale(1.08);
  }

  .article-card-img {
    height: 166px;
    overflow: hidden;
  }

  h5 {
    transition: all 0.36s ease;
    color: #333;
    font-size: 16px;
  }

  &:hover h5 {
    color: $primary;
  }

  .card-text {
    transition: all 0.36s ease;
    font-size: 13px;
    line-height: 26px;
    height: 52px;
    overflow: hidden;
    color: #999;
  }

  .card-body {
    padding: 2.5rem 0 2rem;
  }

  .card-footer {
    transition: all 0.36s ease;
    background-color: unset;
    padding: 0.75rem 0rem;
    position: relative;
  }

  .card-footer:after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0px;
    height: 1px;
    background: $primary;
    width: 60%;
    transition: 0.56s ease;
    opacity: 0;
  }

  &:hover .card-footer:after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0px;
    height: 1px;
    background: $primary;
    width: 100%;
    transition: 0.56s ease;
    opacity: 1;
  }

  strong {
    font-size: 20px;
    line-height: 26px;
    color: #888 !important;
  }

  &:hover .text-muted {
    color: $primary !important;
  }

  .more-icon {
    transition: all 0.36s ease;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    line-height: 54px;
    background-color: transparent;
    border-color: transparent;
    color: transparent;
    border: none;
    padding: 0 20px;
    font-size: 22px;
  }

  &:hover .more-icon .fa {
    color: $primary;
  }

  .more-icon .fa {
    color: inherit;
    transition: all 0.36s ease 0s;
  }

  .container {
    border-left: 1px solid #dadada;
  }
}

// 新闻详情页
.news-detail hr {
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
}

.news-detail .page-content {
  text-indent: 0rem;

  img {
    max-width: 792px;
    width: 100%;
  }

  blockquote hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}