.page-arrows {
    height: 120px;
    margin-bottom: 3rem;
    background-color: $light;

    .page-arrow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        transition: all .36s ease;
        height: 120px;
        padding: 10px 16px;
        overflow: hidden;
        float: left;
        width: 50%;
        color: $gray-700;
        background-color: $light;

        &.page-next {
            float: right;
        }

        &:hover {
            color: #ffffff;
            background-color: $primary;
            text-decoration: none;

            .page-arrow-img {
                visibility: visible;
            }
        }

        &.empty {
            &:hover {
                background-color: $light;
            }
        }

        .page-arrow-img {
            visibility: hidden;
            padding-right: 1rem;

            img {
                max-height: 60px;
                transition: all .1s ease;
            }
        }

        .page-arrow-text {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 1rem;

            span {
                display: inline-block;
                padding-right: 1.25rem;
            }

            i {
                margin-left: auto;
                font-size: 18px;
                padding-left: 1.25rem;
                padding-right: 1rem;
                border-left: 1px solid rgba($color: #000000, $alpha: .1);
            }
        }
    }

    .page-prev {
        flex-direction: row-reverse;

        .page-arrow-text {
            flex-direction: row-reverse;

            i {
                margin-left: 0;
                margin-right: auto;
                font-size: 18px;
                padding-right: 1.25rem;
                padding-left: 1rem;
                border-left: 0;
                border-right: 1px solid rgba($color: #000000, $alpha: .1);
            }
        }
    }
}

@media (max-width: 768px) {
    .page-arrows .page-arrow .page-arrow-img {
        display: none;
    }
}