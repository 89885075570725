.dropdown-menu {
  border-radius: 0;

  .dropdown-item {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  display: inline-block;
  height: 100%;
  transform: rotate(-90deg);
  vertical-align: 0.2em;
  color: $gray-700;
}

.dropdown-submenu a:active::after {
  color: $white;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 2px;
  margin-right: 2px;
}